import React from "react";
import { ThemeProvider, Typography } from "@material-ui/core";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { SnackbarProvider } from "notistack";

import "./App.css";
import theme from "./Styles/theme";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <div className="root-container">
        <SnackbarProvider maxSnack={3}>
          <BrowserRouter>
            <Route
              render={props => (
                // <Layout {...props}>
                <Switch>
                  <Route
                    component={() => (
                      <div
                        style={{
                          display: "flex",
                          flex: 1,
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          flexDirection: "column",
                          gap: 20,
                        }}
                      >
                        <Typography variant="h3">Une erreur est survenue</Typography>
                        <Typography variant="h5">Veuillez réessayer plus tard</Typography>
                        <Typography variant="h5">Si le problème persiste, contactez le support</Typography>
                      </div>
                    )}
                  />
                </Switch>
                // </Layout>
              )}
            />
          </BrowserRouter>
        </SnackbarProvider>
      </div>
    </ThemeProvider>
  );
}

export default observer(App);
