const { createTheme } = require("@material-ui/core");

const theme = createTheme({
  palette: {
    primary: {
      main: "#A69E69",
    },
    secondary: {
      main: "#000000",
    },
  },
});

export default theme;
